<template>
  <div>
    <div class="mall-top">
      <div class="mall-top-body w1200">
        <el-button type="text" size="small" class="mall-btn" @click="goLogin">登录</el-button>
        <el-button type="text" size="small" class="register-btn" @click="goLogin">注册有礼</el-button>
        <div class="mall-top-right">
          <el-button type="text" size="small" class="mall-btn" @click="goLogin">我的订单</el-button>
          <el-button type="text" size="small" class="mall-btn" @click="goLogin">个人中心</el-button>
          <el-button type="text" size="small" class="mall-btn" @click="goTenants">商家入驻</el-button>
          <el-button type="text" size="small" class="mall-btn" @click="goLogin">客服中心</el-button>
        </div>
      </div>
    </div>

    <div class="mall-head">
      <div class="mall-head-body w1200">
        <img src="@/assets/img/logo.jpg" alt="" class="logo" @click="$router.push('/')" />
        <div class="shopping-cart">
          <i class="el-icon-shopping-cart-2" style="font-size: 30px"></i>
          <el-button type="text" size="medium" class="mall-btn" @click="goShoppingCart">我的购物车</el-button>
        </div>
      </div>
    </div>

    <div class="category-box">
      <div class="category w1200">
        <div class="side-category">
          <span>汽车用品分类</span>
        </div>
        <ul class="main-nav">
          <li v-for="(item, index) in activityListData" :key="index">
            <a href="#goodsPosition">{{ item }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { activityListData } from "../utils/index";
export default {
  name: "MallHead",
  components: {},
  data() {
    return {
      activityListData,
    };
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
    goShoppingCart() {
      this.$router.push("/shoppingCart");
    },
    goTenants() {
      this.$router.push("/tenants");
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-top {
  position: relative;
  border-bottom: 1px solid #ddd;
  background-color: #e3e4e5;
  height: 30px;
  color: #5e5e5e;
  z-index: 999;
  .register-btn {
    color: #b20fd3;
  }
  .mall-top-right {
    float: right;
  }
}
.mall-btn {
  color: #888;
  &:hover {
    color: #b20fd3;
  }
}
.mall-head {
  background-color: #fff;
  .mall-head-body {
    height: 120px;
    position: relative;
    .logo {
      width: 175px;
      margin-top: 15px;
      cursor: pointer;
    }
    .shopping-cart {
      position: absolute;
      right: 0;
      top: 45px;
    }
  }
}
.category-box {
  background-color: #fff;
  height: 38px;
  border-bottom: 1px solid #ddd;
}
.side-category {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #8296a0;
  width: 220px;
  height: 38px;
  color: #fff;
  font-size: 18px;
  padding-left: 20px;
  cursor: pointer;
}
.main-nav {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-left: 20px;
  li {
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
  }
}
</style>
