import Vue from "vue";
import VueRouter from "vue-router";
import mallHome from "../views/mallHome.vue";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/", //根路径
  //   redirect: "/mallHome", //重定向url地址
  // },
  {
    path: "/",
    name: "mallHome",
    component: mallHome,
  },
  {
    path: "/goodsDetails:id",
    name: "goodsDetails",
    component: () => import("../views/goodsDetails.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/shoppingCart",
    name: "shoppingCart",
    component: () => import("../views/shoppingCart.vue"),
  },
  {
    path: "/tenants",
    name: "tenants",
    component: () => import("../views/tenants.vue"),
  },
  {
    path: "/sortDetails",
    name: "sortDetails",
    component: () => import("../views/sortDetails.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
