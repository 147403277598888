<template>
  <div class="mall-footer">
    <el-row class="footer-top">
      <el-col :span="6">
        <i class="el-icon-medal"></i>
        <span> 7天退货</span>
      </el-col>
      <el-col :span="6">
        <i class="el-icon-medal"></i>
        <span> 品质承诺</span>
      </el-col>
      <el-col :span="6">
        <i class="el-icon-medal"></i>
        <span> 破损补寄</span>
      </el-col>
      <el-col :span="6">
        <i class="el-icon-medal"></i>
        <span> 急速物流</span>
      </el-col>
    </el-row>
    <div class="footer-body">
      <el-row>
        <el-col :span="8">
          <div class="dt">新手上路</div>
          <div class="dd">
            <span @click="goLogin">查看已购买商品</span>
          </div>
          <div class="dd">
            <span @click="goLogin">我要买</span>
          </div>
          <div class="dd">
            <span @click="goLogin">如何搜索</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="dt">购物指南</div>
          <div class="dd">
            <span @click="goLogin">商品发布</span>
          </div>
          <div class="dd">
            <span @click="goLogin">会员修改个人资料</span>
          </div>
          <div class="dd">
            <span @click="goLogin">会员修改密码</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="dt">联系我们</div>
          <div class="dt_1">18217513297</div>
          <div class="dd_1">08:30-18:00(周一至周五)</div>
          <div class="dt_1">在线客服</div>
          <div class="dd_1">E-mail：18217513297@163.com</div>
        </el-col>
      </el-row>
    </div>
    <FooterICP />
  </div>
</template>

<script>
import FooterICP from "@/views/FooterICP.vue";
export default {
  name: "MallFooter",
  components: {
    FooterICP,
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-top {
  max-width: 1000px;
  margin: 0 auto !important;
  padding: 100px 0 50px;
  color: #303133;
  .el-col {
    text-align: center;
    border-right: 1px solid #d7d7d7;
    &:last-child {
      border-right: 0;
    }
    i {
      font-size: 20px;
    }
  }
}
.footer-body {
  background-color: #fff;
  padding: 50px 0;
  .el-row {
    max-width: 1000px;
    margin: 0 auto !important;
    padding-left: 90px;
    .dt {
      font-size: 16px;
      color: #666;
      font-weight: 700;
      margin-bottom: 12px;
    }
    .dd {
      font-size: 12px;
      color: #999;
      margin-bottom: 6px;
      span {
        cursor: pointer;
        &:hover {
          color: #c81623;
        }
      }
    }
    .dt_1 {
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 13px;
      color: #303133;
    }
    .dd_1 {
      font-size: 12px;
      margin-bottom: 13px;
      color: #999;
    }
  }
}
</style>
