<template>
  <div class="mall-home">
    <mall-head />

    <div class="slide-box">
      <ul>
        <li v-for="(item, index) in sideMenuData" :key="index">
          <span>{{ item.title }}</span>
          <div>
            <el-button
              v-for="(item1, index1) in item.item"
              :key="index1"
              type="text"
              size="small"
              @click="goSortDetails(item1)"
              >{{ item1.name }}</el-button
            >
          </div>
        </li>
      </ul>

      <el-carousel height="400px">
        <el-carousel-item v-for="(item, index) in carouselData" :key="index">
          <img :src="item.img" alt="" @click="openGoods('goods5')" />
        </el-carousel-item>
      </el-carousel>

      <div class="boutique">
        <div class="boutique-body">
          <span class="title">商品精选</span>
          <div class="goods" @click="openGoods('goods1')">
            <img :src="goods_1" alt="" />
            <span class="price">¥{{ goodsData.goods1.price }}</span>
            <div class="details">
              {{ goodsData.goods1.details }}
            </div>
            <div class="comments">已有1500+人评论</div>
          </div>
        </div>
      </div>
    </div>

    <div class="goods-list w1200">
      <div class="title" id="goodsPosition">热销商品</div>
      <el-row :gutter="20">
        <el-col :span="6" v-for="(item, index) in goodsData" :key="index">
          <div class="goods-item" @click="openGoods(item.name)">
            <el-image :src="item.coverImg" />
            <div class="details">
              {{ item.details }}
            </div>
            <div class="price">
              <span>¥ </span>
              <span class="price_num">{{ item.price }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <MallFooter />
  </div>
</template>

<script>
import MallHead from "@/views/MallHead.vue";
import MallFooter from "@/views/MallFooter.vue";
import { carouselData, sideMenuData, goodsData } from "../utils/index";

export default {
  name: "Home",
  components: { MallHead, MallFooter },
  data() {
    return {
      carouselData,
      sideMenuData,
      goodsData,
      goods_1: require("@/assets/goods-img/goods1.jpg"),
    };
  },
  methods: {
    openGoods(name) {
      let routeUrl = this.$router.resolve({
        path: "/goodsDetails" + name,
      });
      window.open(routeUrl.href, "_blank");
    },
    goSortDetails(sort) {
      this.$router.push({
        name: "sortDetails",
        query: sort,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-home {
  background-color: #f4f4f4;
}

.slide-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: red;
  .el-carousel {
    width: 760px;
    img {
      width: 760px;
      height: 400px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  ul {
    width: 220px;
    height: 400px;
    background-color: rgba(71, 71, 71, 0.7);
    color: #fff;
    z-index: 99;
    li {
      padding: 12px 15px;
      &:hover {
        background-color: #fff;
        color: #000;
        .el-button {
          color: #000;
        }
      }
      .el-button {
        color: #fff;
        &:hover {
          color: red;
        }
      }
    }
  }

  .boutique {
    background-color: #ffffff;
    width: 220px;
    height: 400px;
    .boutique-body {
      padding: 15px;
      .title {
        font-size: 16px;
      }
      .goods {
        margin-top: 10px;
        color: #666;
        cursor: pointer;
        img {
          width: 190px;
          margin-bottom: 10px;
        }
        .price {
          color: red;
          font-weight: 700;
          padding-left: 10px;
        }
        .details,
        .comments {
          font-size: 12px;
          margin-top: 15px;
          padding: 0 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}
.goods-list {
  .title {
    color: #8296a0;
    font-weight: 700;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .goods-item {
    height: 370px;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
    }
    .el-image {
      margin: 40px 40px 30px;
    }
    .details {
      margin: 0 20px;
      color: #666;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .price {
      color: red;
      margin: 10px 20px 0;
    }
    .price_num {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
</style>
