<template>
  <div class="footer-icp">
    Copyright @2021 惠享驾购物商城 版权所有 ICP证：
    <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021035583号-1</a>
  </div>
</template>

<script>
export default {
  name: "FooterICP",
};
</script>

<style lang="scss" scoped>
.footer-icp {
  font-size: 14px;
  color: #303133;
  text-align: center;
  padding: 10px 0;
  a {
    color: #fe8787;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #fe6969;
    }
  }
}
</style>
