// 活动列表数据
export const activityListData = [
  "优惠满999减200",
  "优惠满599减100",
  "优惠满299减50",
  "优惠满199减20",
  "优惠满99减5",
];

// 轮播图数据
export const carouselData = [
  {
    img: require("@/assets/carousel-img/car1.jpg"),
    link: "",
  },
  {
    img: require("@/assets/carousel-img/car2.jpg"),
    link: "",
  },
  {
    img: require("@/assets/carousel-img/car3.jpg"),
    link: "",
  },
];

// 轮播图左侧菜单数据
export const sideMenuData = [
  {
    title: "车载电器",
    item: [
      { name: "车载净化器", id: "goods12" },
      { name: "车载按摩椅", id: "goods16" },
    ],
  },
  {
    title: "养护配件",
    item: [
      { name: "车尾带", id: "goods1" },
      { name: "电源", id: "goods11" },
    ],
  },
  {
    title: "美容清洁",
    item: [
      { name: "收纳箱", id: "goods2" },
      { name: "清洁钩", id: "goods4" },
    ],
  },
  {
    title: "车饰精品",
    item: [
      { name: "内饰精品", id: "goods14" },
      { name: "专车专用", id: "goods13" },
    ],
  },
  {
    title: "安全自驾",
    item: [
      { name: "安全座椅", id: "goods6" },
      { name: "自驾装备", id: "goods15" },
    ],
  },
];

// 分类商品数据
export const sortGoods = {
  goods1: {
    goods1: {
      name: "goods1",
      details:
        "汽车静电消除器车载静电带车尾车上防静电接地带车用除静电神器去静电消除静电释放器汽车用品大全配件 【皮卡丘-黄底】静电带（轿车款）",
      price: "19.80",
      coverImg: require("@/assets/goods-img/goods1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods2: {
      name: "goods1",
      details:
        "静电带汽车用防静电轿车SUV车尾排气管去静电绳拖地天线消除释放神器带接地链条汽车用品个性 皮卡丘-静电带【一条装】",
      price: "15.80",
      coverImg: require("@/assets/goods-img/goods1-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods3: {
      name: "goods1",
      details:
        "汽车静电消除器车载静电带车尾车上防静电接地带车用除静电神器去静电消除静电释放器汽车用品大全配件",
      price: "19.80",
      coverImg: require("@/assets/goods-img/goods1-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods4: {
      name: "goods1",
      details:
        "交车红丝带定制新车红布条汽车丝带彩带提车红带子红布条 车尾5*115CM现货版可定制",
      price: "19.90",
      coverImg: require("@/assets/goods-img/goods1-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods5: {
      name: "goods1",
      details:
        "汽车拖车绳5米 拖车带越野牵引绳强力拉绳救援应急拉车绳子捆绑带双层",
      price: "19.80",
      coverImg: require("@/assets/goods-img/goods1-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods6: {
      name: "goods1",
      details:
        "汽车加厚拖车绳轿车小车用绑带车载车用牵引绳5吨8吨救援SUV拉车绳拉车带强力汽车越野车拖车绳拖车带 4米8吨+G钩+无包",
      price: "34.00",
      coverImg: require("@/assets/goods-img/goods1-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods7: {
      name: "goods1",
      details:
        "汽车静电带消除器车尾接地条神器减少静电悬挂式拖地线车载反光静电带轿车SUV越野拖地带汽车用品通用",
      price: "19.80",
      coverImg: require("@/assets/goods-img/goods1-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
    goods8: {
      name: "goods1",
      details:
        "静电带 车尾汽车静电带防静电接地条排气管耐磨拖地带人体去静电消除器释放器 轿车款：皮卡丘(静电带)",
      price: "19.90",
      coverImg: require("@/assets/goods-img/goods1-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods1_1.jpg"),
        require("@/assets/goods-img/goods1_2.jpg"),
        require("@/assets/goods-img/goods1_3.jpg"),
        require("@/assets/goods-img/goods1_4.jpg"),
        require("@/assets/goods-img/goods1_5.jpg"),
        require("@/assets/goods-img/goods1_6.jpg"),
        require("@/assets/goods-img/goods1_7.jpg"),
      ],
    },
  },
  goods2: {
    goods1: {
      name: "goods2",
      details:
        "奔驰汽车后备箱储物箱收纳箱C级A级S级E级e300l/glc260gle级置物盒车用整理箱 【密码锁款大号】车标Mercedes-Benz",
      price: "578.00",
      coverImg: require("../assets/goods-img/goods2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods2: {
      name: "goods2",
      details:
        "汽车收纳箱储物箱 车载后备箱收纳箱 多功能车内尾箱整理箱置物用品 升级带反光三角牌60L（黑灰大号）",
      price: "108.00",
      coverImg: require("../assets/goods-img/goods2-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods3: {
      name: "goods2",
      details:
        "汽车储物箱 车载多功能折叠收纳箱 分离式后备箱置物箱整理箱杂物箱 宽大号",
      price: "76.00",
      coverImg: require("../assets/goods-img/goods2-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods4: {
      name: "goods2",
      details:
        "备箱收纳箱 大容量折叠车载收纳箱 汽车储物箱车载多功能内带反光条加厚",
      price: "129.00",
      coverImg: require("../assets/goods-img/goods2-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods5: {
      name: "goods2",
      details:
        "专用汽车后备箱储物箱储物盒收纳箱 魅力咖【升级带车标+可折叠+无异味+精美毛巾】 专车定制储物箱",
      price: "178.00",
      coverImg: require("../assets/goods-img/goods2-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods6: {
      name: "goods2",
      details:
        "大号折叠车载后备箱收纳箱75L 多功能汽车尾箱整理箱储物箱家用衣服玩具收纳箱",
      price: "139.00",
      coverImg: require("../assets/goods-img/goods2-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods7: {
      name: "goods2",
      details: "汽车后备箱储物箱车用整理箱车载箱子折叠收纳箱置物箱车内杂物箱",
      price: "138.00",
      coverImg: require("../assets/goods-img/goods2-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
    goods8: {
      name: "goods2",
      details:
        "汽车后备箱收纳箱宝马3系5系7系X1X2X5X7车载储物箱6系GT M3车用尾箱整理箱子收纳盒 宝马专用带车标",
      price: "458.00",
      coverImg: require("../assets/goods-img/goods2-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods2_1.jpg"),
        require("@/assets/goods-img/goods2_2.jpg"),
        require("@/assets/goods-img/goods2_3.jpg"),
        require("@/assets/goods-img/goods2_4.jpg"),
        require("@/assets/goods-img/goods2_5.jpg"),
        require("@/assets/goods-img/goods2_6.jpg"),
        require("@/assets/goods-img/goods2_7.jpg"),
      ],
    },
  },
  goods4: {
    goods1: {
      name: "goods4",
      details:
        "汽车轮胎清石钩车胎清理勾石子清理工具 车载安全汽车用品大全车内饰品超市装饰新车配件车上黑科技 轮胎清石钩 1个装",
      price: "17.90",
      coverImg: require("../assets/goods-img/goods4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods2: {
      name: "goods4",
      details:
        "汽车掸子洗车拖把汽车用品超细纤维软毛擦车神器洗车刷子除尘掸扫灰车用可伸缩车家两用清洁工具套装 套餐：除尘子母刷+轮胎清石钩",
      price: "39.90",
      coverImg: require("../assets/goods-img/goods4-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods3: {
      name: "goods4",
      details:
        "汽车细节刷洗车刷子软毛清洁刷轮毂内饰清理毛刷车用精洗美容工具 细节刷(5件套)+出风口清洁刷+轮胎清石钩",
      price: "22.80",
      coverImg: require("../assets/goods-img/goods4-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods4: {
      name: "goods4",
      details:
        "汽车轮毂刷车用钢圈刷子洗车轮胎刷专用清洁清洗美容工具精洗神器刷的轮胎干干净净 轮胎刷套装+轮胎清石钩",
      price: "33.80",
      coverImg: require("../assets/goods-img/goods4-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods5: {
      name: "goods4",
      details:
        "汽车轮胎清理勾清石钩 石子清理工具去除小石头多功能器轮胎剔牙刀挑抠挖剔钩车胎取石器",
      price: "13.00",
      coverImg: require("../assets/goods-img/goods4-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods6: {
      name: "goods4",
      details:
        "汽车轮胎清石钩多功能清理车胎石子工具汽车用品抠石头沟槽缝隙便携式不锈钢螺丝刀车胎扣取挑石器",
      price: "9.80",
      coverImg: require("../assets/goods-img/goods4-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods7: {
      name: "goods4",
      details:
        "洗汽车轮毂刷轮胎刷子专用刷内洗车神器刷车清洗工具清洗刷清洁刷 轮胎刷+轮毂刷+缝隙刷+清石钩",
      price: "25.00",
      coverImg: require("../assets/goods-img/goods4-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
    goods8: {
      name: "goods4",
      details:
        "汽车轮胎石子清理工具 多功能车胎清石钩抠石头器去扣挑勾除取石剔除清理勾配手套带收纳袋",
      price: "9.90",
      coverImg: require("../assets/goods-img/goods4-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods4_1.jpg"),
        require("@/assets/goods-img/goods4_2.jpg"),
        require("@/assets/goods-img/goods4_3.jpg"),
        require("@/assets/goods-img/goods4_4.jpg"),
        require("@/assets/goods-img/goods4_5.jpg"),
        require("@/assets/goods-img/goods4_6.jpg"),
      ],
    },
  },
  goods6: {
    goods1: {
      name: "goods6",
      details:
        "汽车头枕腰靠套装 护颈枕车用座椅靠枕电动按摩记忆棉车内颈椎枕头腰靠 震动按摩款-经典黑【头枕+腰靠】",
      price: "185.00",
      coverImg: require("../assets/goods-img/goods6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods2: {
      name: "goods6",
      details: "汽车儿童安全座椅 isofix接口（约0-4-7岁）360°旋转 i-Size认证",
      price: "2880.00",
      coverImg: require("../assets/goods-img/goods6-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods3: {
      name: "goods6",
      details:
        "儿童安全座椅汽车用0-12岁婴儿宝宝360度旋转安全椅ISOFIX接口新生儿车载座椅坐躺（360°旋转+双接口+钢骨架）",
      price: "599.00",
      coverImg: require("../assets/goods-img/goods6-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods4: {
      name: "goods6",
      details:
        "儿童安全座椅0-12岁9个月以上适用安全带/ISOFIX接口车载安全座椅儿童座椅汽车 伦敦红-安全带固定-便携可折叠",
      price: "229.00",
      coverImg: require("../assets/goods-img/goods6-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods5: {
      name: "goods6",
      details:
        "儿童安全座椅汽车用婴儿宝宝车载安全座椅可坐可躺0-4-12岁isofix硬接口 星空灰(360°旋转，0-12岁）",
      price: "1080.00",
      coverImg: require("../assets/goods-img/goods6-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods6: {
      name: "goods6",
      details:
        "儿童安全座椅汽车用0-4-12岁双向安装isofix硬接口宝宝婴儿童坐椅车载 小灰灰",
      price: "1180.00",
      coverImg: require("../assets/goods-img/goods6-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods7: {
      name: "goods6",
      details: "儿童安全座椅汽车用宝宝椅婴儿车载360度旋转0318岁通用",
      price: "649.00",
      coverImg: require("../assets/goods-img/goods6-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
    goods8: {
      name: "goods6",
      details: "汽车用儿童安全座椅3-12周岁大童车载简易便携式坐椅ISOFIX 珊瑚橙",
      price: "468.00",
      coverImg: require("../assets/goods-img/goods6-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods6_1.jpg"),
        require("@/assets/goods-img/goods6_2.jpg"),
        require("@/assets/goods-img/goods6_3.jpg"),
        require("@/assets/goods-img/goods6_4.jpg"),
        require("@/assets/goods-img/goods6_5.jpg"),
        require("@/assets/goods-img/goods6_6.jpg"),
      ],
    },
  },
  goods11: {
    goods11: {
      name: "goods11",
      details:
        "汽车应急启动电源24v12v通用卡车大货车搭电宝车载电瓶充电器帮电救援打火神器 188000mAh-12v24v货车司机自用力荐",
      price: "538.00",
      coverImg: require("../assets/goods-img/goods11.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "S400Plus汽车应急启动电源12V车载电瓶启动宝汽车打火器搭电宝车载充电宝手机移动电源",
      price: "398.00",
      coverImg: require("../assets/goods-img/goods11-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "汽车应急启动电源12v移动车载电瓶车用多功能备用户外搭火启动宝充电器 【X5】15800mAh 标配版+加粗电瓶夹",
      price: "118.00",
      coverImg: require("../assets/goods-img/goods11-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "D3汽车应急启动电源12V车载大容量多功能电瓶充电器搭电宝户外移动打火神器 标配版+加粗电瓶夹",
      price: "148.00",
      coverImg: require("../assets/goods-img/goods11-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "X5D汽车应急启动电源12v车载电瓶多功能户外搭电移动充电宝备用打火神器 15800mAh 标准款+加粗电瓶夹",
      price: "168.00",
      coverImg: require("../assets/goods-img/goods11-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "W18汽车应急启动电源 12V车载电瓶启动打火搭电器充电宝 力荐新款雷霆2代11400mAh 400A启动电流",
      price: "268.00",
      coverImg: require("../assets/goods-img/goods11-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "汽车应急启动电源12V车载电瓶启动汽车启动打火车载充电宝快充套装 黑色(ES300+208快充）",
      price: "339.00",
      coverImg: require("../assets/goods-img/goods11-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
    goods11: {
      name: "goods11",
      details:
        "汽车应急启动电源12v车载户外备用大容量搭电宝电瓶打火器汽车启动宝移动手机充电宝 22800mAh-标准版+威力加强",
      price: "169.00",
      coverImg: require("../assets/goods-img/goods11-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods11_1.jpg"),
        require("@/assets/goods-img/goods11_2.jpg"),
        require("@/assets/goods-img/goods11_3.jpg"),
        require("@/assets/goods-img/goods11_4.jpg"),
        require("@/assets/goods-img/goods11_5.jpg"),
        require("@/assets/goods-img/goods11_6.jpg"),
      ],
    },
  },
  goods12: {
    goods1: {
      name: "goods12",
      details:
        "太阳能车载空气净化器车内智能车用消除异味负离子汽车甲醛 珍珠白套餐二",
      price: "979.00",
      coverImg: require("../assets/goods-img/goods12.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods2: {
      name: "goods12",
      details:
        "车载空气净化器 负离子除甲醛甲苯新车除异味智能便携香薰车用杀菌去烟味",
      price: "399.00",
      coverImg: require("../assets/goods-img/goods12-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods3: {
      name: "goods12",
      details:
        "新车除味除甲醛 车内除味汽车空气净化器 车载香薰汽车用品摆件非活性炭包源头除醛祛异味车家两用",
      price: "79.00",
      coverImg: require("../assets/goods-img/goods12-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods4: {
      name: "goods12",
      details:
        "车载空气净化器 除甲醛净化器汽车空气净化器除味除PM2.5高效过滤HMS-AQ-02",
      price: "439.00",
      coverImg: require("../assets/goods-img/goods12-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods5: {
      name: "goods12",
      details:
        "车载空气净化器无线 新汽车用负离子发生器去除甲醛杀菌异味烟臭味 至尊版=UVC杀菌+五层过滤+太阳能随车启停+香水 新升级车载净化器",
      price: "298.00",
      coverImg: require("../assets/goods-img/goods12-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods6: {
      name: "goods12",
      details:
        "载空气净化器太阳能无线 汽车内用除甲醛 负离子发生器 新车除异味烟味PM2.5 汽车用品香薰 黑色-强效净化版",
      price: "288.00",
      coverImg: require("../assets/goods-img/goods12-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods7: {
      name: "goods12",
      details:
        "车载空气净化器 除甲醛净化器汽车空气净化器除味除PM2.5高效过滤HMS-AQ-02",
      price: "459.00",
      coverImg: require("../assets/goods-img/goods12-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },
    goods8: {
      name: "goods12",
      details:
        "车载空气净化器 车家两用汽车车内PM2.5异味净化器 HWC01",
      price: "399.00",
      coverImg: require("../assets/goods-img/goods12-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods12_1.jpg"),
        require("@/assets/goods-img/goods12_2.jpg"),
        require("@/assets/goods-img/goods12_3.jpg"),
        require("@/assets/goods-img/goods12_4.jpg"),
        require("@/assets/goods-img/goods12_5.jpg"),
        require("@/assets/goods-img/goods12_6.jpg"),
      ],
    },

  },
  goods13: {
    goods1: {
      name: "goods13",
      details:
        "适用于英菲尼迪Q50L QX30 QX50改装电动折叠后视镜升级高配电动折耳一键升窗器加热镜片原厂 到厂安装预付定金安装调试好付尾款",
      price: "688.00",
      coverImg: require("../assets/goods-img/goods13.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods13_1.jpg"),
        require("@/assets/goods-img/goods13_2.jpg"),
        require("@/assets/goods-img/goods13_3.jpg"),
        require("@/assets/goods-img/goods13_4.jpg"),
        require("@/assets/goods-img/goods13_5.jpg"),
        require("@/assets/goods-img/goods13_6.jpg"),
      ],
    },
    goods2: {
      name: "goods13",
      details:
        "专用于现代名图13-19款中控台防晒垫仪表台遮阳隔热汽车装饰改装定制避光垫",
      price: "38.00",
      coverImg: require("../assets/goods-img/goods13-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods13_1.jpg"),
        require("@/assets/goods-img/goods13_2.jpg"),
        require("@/assets/goods-img/goods13_3.jpg"),
        require("@/assets/goods-img/goods13_4.jpg"),
        require("@/assets/goods-img/goods13_5.jpg"),
        require("@/assets/goods-img/goods13_6.jpg"),
      ],
    },
    goods3: {
      name: "goods13",
      details:
        "专用于17-21款吉利帝豪GL汽车后备箱垫装饰改装定制尾箱垫",
      price: "89.00",
      coverImg: require("../assets/goods-img/goods13-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods13_1.jpg"),
        require("@/assets/goods-img/goods13_2.jpg"),
        require("@/assets/goods-img/goods13_3.jpg"),
        require("@/assets/goods-img/goods13_4.jpg"),
        require("@/assets/goods-img/goods13_5.jpg"),
        require("@/assets/goods-img/goods13_6.jpg"),
      ],
    },
    goods4: {
      name: "goods13",
      details:
        "本田冠道奥德赛艾力绅URV坐垫 04-21新老款5座7座专车定制真皮透气全包围座椅套四季通用冬季座垫 神秘黑【360全包围主图纹路】 20 19 18 17 16 冠道URV五座专拍",
      price: "468.00",
      coverImg: require("../assets/goods-img/goods13-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods13_1.jpg"),
        require("@/assets/goods-img/goods13_2.jpg"),
        require("@/assets/goods-img/goods13_3.jpg"),
        require("@/assets/goods-img/goods13_4.jpg"),
        require("@/assets/goods-img/goods13_5.jpg"),
        require("@/assets/goods-img/goods13_6.jpg"),
      ],
    },
    goods5: {
      name: "goods13",
      details:
        "朗逸脚垫 适用于08-2022款大众朗逸plus全包围包门槛脚踏垫专车专用启航汽车双层丝圈防水脚踩垫 双层【黑米+灰色丝圈】 19/21/22款朗逸定制新老款原车内饰装饰改装",
      price: "358.00",
      coverImg: require("../assets/goods-img/goods13-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods13_1.jpg"),
        require("@/assets/goods-img/goods13_2.jpg"),
        require("@/assets/goods-img/goods13_3.jpg"),
        require("@/assets/goods-img/goods13_4.jpg"),
        require("@/assets/goods-img/goods13_5.jpg"),
        require("@/assets/goods-img/goods13_6.jpg"),
      ],
    },
  },
  goods14: {
    goods1: {
      name: "goods14",
      details:
        "汽车挂件车载车内饰品出入平安符玉石挂饰男女高档后视镜装饰用品小车上香水吊坠 天然黑曜石-揽财双貔貅-平安福",
      price: "178.00",
      coverImg: require("../assets/goods-img/goods14.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
    goods2: {
      name: "goods14",
      details:
        "创意卡通可爱汽车摆件车内饰品车载车上网红小公仔装饰品用品大全女 10个小摆件",
      price: "35.80",
      coverImg: require("../assets/goods-img/goods14-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
    goods3: {
      name: "goods14",
      details:
        "汽车香水车载香水车用香水挂饰汽车用品香薰挂件车内饰品汽车装饰汽车香水挂件木质挂饰香水 六边形 古龙",
      price: "88.00",
      coverImg: require("../assets/goods-img/goods14-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
    goods4: {
      name: "goods14",
      details:
        "汽车摆件一路平安鹿车内饰品车载摆件个性创意汽车内中控台装饰用品 摇头和尚+吉祥果+大号梅花鹿",
      price: "49.90",
      coverImg: require("../assets/goods-img/goods14-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
    goods5: {
      name: "goods14",
      details:
        "2022新款网红汽车摆件车载香水马到成功内饰用品高档大气创意中控台男女士霸气车子车上车内装饰用品大全 新款镀金马到成功+藏银珠",
      price: "268.00",
      coverImg: require("../assets/goods-img/goods14-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
    goods6: {
      name: "goods14",
      details:
        "汽车摆件2021新款 创意个性卡通车用车内饰品 车上小车装饰品轿车可爱女神款高档车载中控台摆件",
      price: "45.00",
      coverImg: require("../assets/goods-img/goods14-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods14_1.jpg"),
        require("@/assets/goods-img/goods14_2.jpg"),
        require("@/assets/goods-img/goods14_3.jpg"),
        require("@/assets/goods-img/goods14_4.jpg"),
        require("@/assets/goods-img/goods14_5.jpg"),
        require("@/assets/goods-img/goods14_6.jpg"),
      ],
    },
  },
  goods15: {
    goods1: {
      name: "goods15",
      details: "车用腿托腿部支撑延长垫后排休息脚踏板脚托车载搁脚凳翘腿凳 黑色款",
      price: "188.00",
      coverImg: require("../assets/goods-img/goods15.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods2: {
      name: "goods15",
      details: "加厚便携式油桶 30升军绿色方桶带导油管 汽车摩托车载应急汽油柴油备用油箱自驾游装备",
      price: "99.00",
      coverImg: require("../assets/goods-img/goods15-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods3: {
      name: "goods15",
      details: "车用应急包套装汽车应急工具车载救援急救包年检车检救援应急包救援应急装备车载干粉灭火器自驾游装备组合 B三角架款",
      price: "78.00",
      coverImg: require("../assets/goods-img/goods15-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods4: {
      name: "goods15",
      details: "车载充气床 双重护档 舒缓波纹 汽车用后排充气床垫 旅行气垫床 轿车睡垫自驾游装备用品 黑色 F35",
      price: "158.00",
      coverImg: require("../assets/goods-img/goods15-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods5: {
      name: "goods15",
      details: "车载床车载充气床双重护档 汽车用后排充气床垫 旅行气垫床 轿车睡垫自驾游装备用品 加厚普通款【黑色】",
      price: "109.00",
      coverImg: require("../assets/goods-img/goods15-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods6: {
      name: "goods15",
      details: "汽车除雪铲子神器冬季扫雪刷铲雪工具车用车载车上用品车内饰品装饰大全黑科技自驾游装备功能小件 套餐：除雪除尘洗车子母刷+除雪铲标准款",
      price: "39.90",
      coverImg: require("../assets/goods-img/goods15-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
    goods7: {
      name: "goods15",
      details: "汽车脱困板越野自驾游装备防滑防陷板防沙板牵引板应急雪地自救援轮胎防滑链履带",
      price: "15.00",
      coverImg: require("../assets/goods-img/goods15-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods15_1.jpg"),
        require("@/assets/goods-img/goods15_2.jpg"),
      ],
    },
  },
  goods16: {
    goods1: {
      name: "goods16",
      details: "汽车加热坐垫冬季座椅按摩车载座垫四季通用 加热+按摩(5按摩头)黑色",
      price: "169.00",
      coverImg: require("../assets/goods-img/goods16.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods2: {
      name: "goods16",
      details: "腰部按摩器 腰部按摩仪腰肌劳损腰背部揉捏捶打震动按摩靠垫车载家用办公热敷 智能款",
      price: "399.00",
      coverImg: require("../assets/goods-img/goods16-1.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods3: {
      name: "goods16",
      details: "无线颈椎按摩器 按摩靠垫全身颈部腰部肩部按摩椅垫 车载按摩枕肩颈按摩仪",
      price: "239.00",
      coverImg: require("../assets/goods-img/goods16-2.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods4: {
      name: "goods16",
      details: "汽车头枕腰靠车载按摩靠垫电动加热靠枕腰靠头枕车用靠背腰垫u型枕护颈枕汽车枕头座椅靠垫套装定制",
      price: "279.00",
      coverImg: require("../assets/goods-img/goods16-3.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods5: {
      name: "goods16",
      details: "车载按摩垫颈椎按摩器 肩部腰部坐垫家用全身按摩椅靠垫 多功能全身按摩仪 F-887B黑色",
      price: "599.00",
      coverImg: require("../assets/goods-img/goods16-4.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods6: {
      name: "goods16",
      details: "汽车头枕腰靠垫车载电动按摩腰部支撑颈枕太空记忆棉行车靠枕座椅靠背办公室靠垫四季通用长途午睡旅行 按摩款-经典黑【头枕+腰靠】",
      price: "298.00",
      coverImg: require("../assets/goods-img/goods16-5.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods7: {
      name: "goods16",
      details: "智能按摩垫全身 腰背按摩靠垫 颈椎按摩器按摩椅垫 家用车载按摩坐垫 S730",
      price: "1388.00",
      coverImg: require("../assets/goods-img/goods16-6.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
    goods8: {
      name: "goods16",
      details: "车载按摩垫全身 腰背部按摩仪颈椎按摩器 汽车用按摩靠垫 多功能按摩椅垫",
      price: "2580.00",
      coverImg: require("../assets/goods-img/goods16-7.jpg"),
      detailsImg: [
        require("@/assets/goods-img/goods16_1.jpg"),
        require("@/assets/goods-img/goods16_2.jpg"),
        require("@/assets/goods-img/goods16_3.jpg"),
        require("@/assets/goods-img/goods16_4.jpg"),
        require("@/assets/goods-img/goods16_5.jpg"),
        require("@/assets/goods-img/goods16_6.jpg"),
      ],
    },
  },
};

// 商品数据
export const goodsData = {
  goods1: {
    name: "goods1",
    details:
      "汽车静电消除器车载静电带车尾车上防静电接地带车用除静电神器去静电消除静电释放器汽车用品大全配件 【皮卡丘-黄底】静电带（轿车款）",
    price: "19.80",
    coverImg: require("@/assets/goods-img/goods1.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods1_1.jpg"),
      require("@/assets/goods-img/goods1_2.jpg"),
      require("@/assets/goods-img/goods1_3.jpg"),
      require("@/assets/goods-img/goods1_4.jpg"),
      require("@/assets/goods-img/goods1_5.jpg"),
      require("@/assets/goods-img/goods1_6.jpg"),
      require("@/assets/goods-img/goods1_7.jpg"),
    ],
  },
  goods2: {
    name: "goods2",
    details:
      "奔驰汽车后备箱储物箱收纳箱C级A级S级E级e300l/glc260gle级置物盒车用整理箱 【密码锁款大号】车标Mercedes-Benz",
    price: "578.00",
    coverImg: require("../assets/goods-img/goods2.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods2_1.jpg"),
      require("@/assets/goods-img/goods2_2.jpg"),
      require("@/assets/goods-img/goods2_3.jpg"),
      require("@/assets/goods-img/goods2_4.jpg"),
      require("@/assets/goods-img/goods2_5.jpg"),
      require("@/assets/goods-img/goods2_6.jpg"),
      require("@/assets/goods-img/goods2_7.jpg"),
    ],
  },
  goods3: {
    name: "goods3",
    details:
      "挪车电话牌 临时停车号码牌汽车移车电话牌 车载车上留双号码大数字滚动可隐藏式铝合金车内装饰用品",
    price: "33.60",
    coverImg: require("../assets/goods-img/goods3.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods3_1.jpg"),
      require("@/assets/goods-img/goods3_2.jpg"),
      require("@/assets/goods-img/goods3_3.jpg"),
      require("@/assets/goods-img/goods3_4.jpg"),
      require("@/assets/goods-img/goods3_5.jpg"),
      require("@/assets/goods-img/goods3_6.jpg"),
    ],
  },
  goods4: {
    name: "goods4",
    details:
      "汽车轮胎清石钩车胎清理勾石子清理工具 车载安全汽车用品大全车内饰品超市装饰新车配件车上黑科技 轮胎清石钩 1个装",
    price: "17.90",
    coverImg: require("../assets/goods-img/goods4.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods4_1.jpg"),
      require("@/assets/goods-img/goods4_2.jpg"),
      require("@/assets/goods-img/goods4_3.jpg"),
      require("@/assets/goods-img/goods4_4.jpg"),
      require("@/assets/goods-img/goods4_5.jpg"),
      require("@/assets/goods-img/goods4_6.jpg"),
    ],
  },
  goods5: {
    name: "goods5",
    details:
      "别克英朗座套真皮全包围 专用10-21款2015款别克新英朗精英版汽车座套坐垫四季通用座垫座椅套冬季 全包围玛莎皮【神秘黑】标准版 21 20 19 18 17-10款【英朗】定制",
    price: "698.00",
    coverImg: require("../assets/goods-img/goods5.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods5_1.jpg"),
      require("@/assets/goods-img/goods5_2.jpg"),
      require("@/assets/goods-img/goods5_3.jpg"),
      require("@/assets/goods-img/goods5_4.jpg"),
      require("@/assets/goods-img/goods5_5.jpg"),
      require("@/assets/goods-img/goods5_6.jpg"),
    ],
  },
  goods6: {
    name: "goods6",
    details:
      "汽车头枕腰靠套装 护颈枕车用座椅靠枕电动按摩记忆棉车内颈椎枕头腰靠 震动按摩款-经典黑【头枕+腰靠】",
    price: "185.00",
    coverImg: require("../assets/goods-img/goods6.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods6_1.jpg"),
      require("@/assets/goods-img/goods6_2.jpg"),
      require("@/assets/goods-img/goods6_3.jpg"),
      require("@/assets/goods-img/goods6_4.jpg"),
      require("@/assets/goods-img/goods6_5.jpg"),
      require("@/assets/goods-img/goods6_6.jpg"),
    ],
  },
  goods7: {
    name: "goods7",
    details:
      "汽车头枕护颈枕车用座椅靠枕电动按摩记忆棉车内颈椎枕头腰靠一对GK81 振动按摩款太空黑『头枕+腰靠』",
    price: "599.00",
    coverImg: require("../assets/goods-img/goods7.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods7_1.jpg"),
      require("@/assets/goods-img/goods7_2.jpg"),
      require("@/assets/goods-img/goods7_3.jpg"),
      require("@/assets/goods-img/goods7_4.jpg"),
      require("@/assets/goods-img/goods7_5.jpg"),
      require("@/assets/goods-img/goods7_6.jpg"),
    ],
  },
  goods8: {
    name: "goods8",
    details:
      "适用于汽车用品金属滚轮式汽车临时停车电话号码牌挪车移车停车卡内饰摆件 可用十年【炫酷黑】纯合金",
    price: "4.80",
    coverImg: require("../assets/goods-img/goods8.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods8_1.jpg"),
      require("@/assets/goods-img/goods8_2.jpg"),
      require("@/assets/goods-img/goods8_3.jpg"),
      require("@/assets/goods-img/goods8_4.jpg"),
      require("@/assets/goods-img/goods8_5.jpg"),
      require("@/assets/goods-img/goods8_6.jpg"),
    ],
  },
  goods9: {
    name: "goods9",
    details:
      "车载空气净化器杯座式便携式汽车内除味杀菌除甲醛烟味负离子净化器车载 典雅黑",
    price: "1580.00",
    coverImg: require("../assets/goods-img/goods9.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods9_1.jpg"),
      require("@/assets/goods-img/goods9_2.jpg"),
      require("@/assets/goods-img/goods9_3.jpg"),
      require("@/assets/goods-img/goods9_4.jpg"),
      require("@/assets/goods-img/goods9_5.jpg"),
      require("@/assets/goods-img/goods9_6.jpg"),
    ],
  },
  goods10: {
    name: "goods10",
    details:
      "2021新款运动亚麻汽车坐垫四季通用全包围布艺透气秋冬汽车座套座垫棉麻五座免拆安装汽车座椅套 A39米咖色-标准版-五座通用适用于 新轩逸经典轩逸逍客楼兰骊威骐达新天籁新奇骏",
    price: "328.00",
    coverImg: require("../assets/goods-img/goods10.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods10_1.jpg"),
      require("@/assets/goods-img/goods10_2.jpg"),
      require("@/assets/goods-img/goods10_3.jpg"),
      require("@/assets/goods-img/goods10_4.jpg"),
      require("@/assets/goods-img/goods10_5.jpg"),
      require("@/assets/goods-img/goods10_6.jpg"),
    ],
  },
  goods11: {
    name: "goods11",
    details:
      "汽车应急启动电源24v12v通用卡车大货车搭电宝车载电瓶充电器帮电救援打火神器 188000mAh-12v24v货车司机自用力荐",
    price: "538.00",
    coverImg: require("../assets/goods-img/goods11.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods11_1.jpg"),
      require("@/assets/goods-img/goods11_2.jpg"),
      require("@/assets/goods-img/goods11_3.jpg"),
      require("@/assets/goods-img/goods11_4.jpg"),
      require("@/assets/goods-img/goods11_5.jpg"),
      require("@/assets/goods-img/goods11_6.jpg"),
    ],
  },
  goods12: {
    name: "goods12",
    details:
      "太阳能车载空气净化器车内智能车用消除异味负离子汽车甲醛 珍珠白套餐二",
    price: "979.00",
    coverImg: require("../assets/goods-img/goods12.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods12_1.jpg"),
      require("@/assets/goods-img/goods12_2.jpg"),
      require("@/assets/goods-img/goods12_3.jpg"),
      require("@/assets/goods-img/goods12_4.jpg"),
      require("@/assets/goods-img/goods12_5.jpg"),
      require("@/assets/goods-img/goods12_6.jpg"),
    ],
  },
  goods13: {
    name: "goods13",
    details:
      "适用于英菲尼迪Q50L QX30 QX50改装电动折叠后视镜升级高配电动折耳一键升窗器加热镜片原厂 到厂安装预付定金安装调试好付尾款",
    price: "688.00",
    coverImg: require("../assets/goods-img/goods13.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods13_1.jpg"),
      require("@/assets/goods-img/goods13_2.jpg"),
      require("@/assets/goods-img/goods13_3.jpg"),
      require("@/assets/goods-img/goods13_4.jpg"),
      require("@/assets/goods-img/goods13_5.jpg"),
      require("@/assets/goods-img/goods13_6.jpg"),
    ],
  },
  goods14: {
    name: "goods14",
    details:
      "汽车挂件车载车内饰品出入平安符玉石挂饰男女高档后视镜装饰用品小车上香水吊坠 天然黑曜石-揽财双貔貅-平安福",
    price: "178.00",
    coverImg: require("../assets/goods-img/goods14.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods14_1.jpg"),
      require("@/assets/goods-img/goods14_2.jpg"),
      require("@/assets/goods-img/goods14_3.jpg"),
      require("@/assets/goods-img/goods14_4.jpg"),
      require("@/assets/goods-img/goods14_5.jpg"),
      require("@/assets/goods-img/goods14_6.jpg"),
    ],
  },
  goods15: {
    name: "goods15",
    details: "车用腿托腿部支撑延长垫后排休息脚踏板脚托车载搁脚凳翘腿凳 黑色款",
    price: "188.00",
    coverImg: require("../assets/goods-img/goods15.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods15_1.jpg"),
      require("@/assets/goods-img/goods15_2.jpg"),
    ],
  },
  goods16: {
    name: "goods16",
    details: "汽车加热坐垫冬季座椅按摩车载座垫四季通用 加热+按摩(5按摩头)黑色",
    price: "169.00",
    coverImg: require("../assets/goods-img/goods16.jpg"),
    detailsImg: [
      require("@/assets/goods-img/goods16_1.jpg"),
      require("@/assets/goods-img/goods16_2.jpg"),
      require("@/assets/goods-img/goods16_3.jpg"),
      require("@/assets/goods-img/goods16_4.jpg"),
      require("@/assets/goods-img/goods16_5.jpg"),
      require("@/assets/goods-img/goods16_6.jpg"),
    ],
  },
};

// 筛选条件
export const filter = ["综合", "销量", "新品", "评价", "价格"];
